import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { getEvents, getParticipantInfo } from "@/feature-battles/api.mjs";
import { EVENT_STORAGE_TIME } from "@/feature-battles/constants.mjs";
import {
  fetchUserOptIns,
  getCurrentFilters,
} from "@/feature-battles/fetch-event-overview.mjs";
import EventsModel from "@/feature-battles/model-events.mjs";
import ParticipantInfoModel from "@/feature-battles/model-participants-info.mjs";
import { mergeEvents } from "@/feature-battles/utils.mjs";
import { devError } from "@/util/dev.mjs";

export async function fetchEventDetails([id]) {
  const requiredPromises = [];
  const sideLoadPromises = [];

  const filters = await getCurrentFilters();
  await getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
    networkBackOffTime: EVENT_STORAGE_TIME,
    // mergeFn: mergeEvents,
  });

  sideLoadPromises.push(
    fetchUserOptIns(filters, {
      skipSafetyCheck: true,
      // mergeFn: mergeEvents,
    }),
    getData(
      getParticipantInfo(filters),
      ParticipantInfoModel,
      ["battles", "eventParticipantInfo"],
      {
        // mergeFn: mergeEvents
      },
    ),
  );

  // Handle past or future events
  const event = readState.battles.eventMap[id];
  if (!event || event instanceof Error) {
    requiredPromises.push(
      getData(getEvents({ ids: [id] }), EventsModel, ["battles", "eventMap"], {
        mergeFn: mergeEvents,
      }),
      getData(
        getParticipantInfo({ ids: [id] }),
        ParticipantInfoModel,
        ["battles", "eventParticipantInfo"],
        { mergeFn: mergeEvents },
      ),
    );

    sideLoadPromises.push(
      fetchUserOptIns(
        { ids: [id] },
        { skipSafetyCheck: true, mergeFn: mergeEvents },
      ),
    );
  }

  Promise.all(sideLoadPromises).catch((error) => {
    devError("FETCH EVENTS SIDELOAD", error);
  });
  return Promise.all(requiredPromises);
}
