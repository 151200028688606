import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import {
  getEvents,
  getGameResult,
  getParticipantInfo,
} from "@/feature-battles/api.mjs";
import {
  BATTLES_NAME,
  EVENT_STORAGE_TIME,
  UTD_STORAGE_TIME,
} from "@/feature-battles/constants.mjs";
import {
  fetchUserOptIns,
  getCurrentFilters,
} from "@/feature-battles/fetch-event-overview.mjs";
import EventsModel from "@/feature-battles/model-events.mjs";
import ParticipantInfoModel from "@/feature-battles/model-participants-info.mjs";
import PostMatchResultModel from "@/feature-battles/model-post-match-result.mjs";
// import { mergeEvents } from "@/feature-battles/utils.mjs";
import { devError } from "@/util/dev.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";

export async function fetchPostMatch({ gameId, tab, tabId, isOwnProfile }) {
  const sideLoadPromises = [];

  const filters = await getCurrentFilters();
  await getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
    networkBackOffTime: EVENT_STORAGE_TIME,
    // mergeFn: mergeEvents,
  });

  if (tab !== BATTLES_NAME || !tabId || !readState.battles.eventMap[tabId])
    return;

  sideLoadPromises.push(
    getData(
      getParticipantInfo({ ids: [tabId] }),
      ParticipantInfoModel,
      ["battles", "eventParticipantInfo"],
      {
        // mergeFn: mergeEvents
      },
    ),
  );

  async function getUserInfo() {
    if (!isOwnProfile) return;

    await fetchUserOptIns(filters, { skipSafetyCheck: true });
    const game = findGameSymbol();
    const bearerToken = await getBearerToken();
    const optIn = readState.battles.userOptIns[tabId];
    if (optIn?.optedIn) {
      const { userAccountId, gameAccountId } = optIn;
      return getData(
        getGameResult({
          eventId: tabId,
          game,
          userAccountId,
          gameAccountId,
          gameId,
        }),
        PostMatchResultModel,
        ["battles", "gameResults", gameId],
        {
          headers: { Authorization: bearerToken },
          skipSafetyCheck: true,
          networkBackOffTime: UTD_STORAGE_TIME,
        },
      );
    }
  }
  sideLoadPromises.push(getUserInfo());

  Promise.all(sideLoadPromises).catch((error) => {
    devError("FETCH EVENTS SIDELOAD", error);
  });
}
