import { postData } from "@/__main__/get-data.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { sendValorantGameRecord } from "@/feature-battles/api.mjs";
import { getHashedSecurityHeader } from "@/feature-battles/event-hooks-util.mjs";
import GameRecordModel from "@/feature-battles/model-game-record.mjs";

// TODO: add hooks for reindex

export async function createValorantGameRecord({ data }) {
  const bearerToken = await getBearerToken();
  const securityHeader = await getHashedSecurityHeader({
    userId: data.userAccountId,
    gameAccountId: data.valorantAccountId,
  });
  return postData(
    sendValorantGameRecord({
      ...data,
      mode: data.mode.toUpperCase(),
      queue: data.queue.toUpperCase(),
    }),
    GameRecordModel,
    undefined,
    {
      headers: {
        Authorization: bearerToken,
        "blitz-challenge-record-id": securityHeader,
      },
    },
  );
}
