import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
} from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import {
  createLeagueGameRecord,
  getLeagueOverlayInfo,
} from "@/feature-battles/event-hooks-lol.mjs";
import { createValorantGameRecord } from "@/feature-battles/event-hooks-valorant.mjs";
import { MAP_ID, QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { QUEUE_SYMBOL_TO_OBJECT } from "@/game-lol/utils/symbol-queue.mjs";
import { devWarn } from "@/util/dev.mjs";

const lolMatchRecord =
  ({ postMatch }) =>
  (gameInfo) => {
    if (!gameInfo) {
      devWarn("You must include an object with match data to send.");
    }

    return createLeagueGameRecord({ postMatch, data: gameInfo });
  };

export const lolMatchStart = lolMatchRecord({ postMatch: false });
export const lolMatchEnd = lolMatchRecord({ postMatch: true });

export const valMatchEnd = (gameInfo) => {
  if (!gameInfo) {
    devWarn("You must include an object with match data to send.");
  }

  return createValorantGameRecord({ data: gameInfo });
};

export const clearDismissedEvents = () => {
  const live = [];
  live[isPersistent] = MAX_TIME;
  const prelive = [];
  prelive[isPersistent] = MAX_TIME;
  const nav = [];
  nav[isPersistent] = MAX_TIME;
  writeState.battles.dismissedEvents.live = live;
  writeState.battles.dismissedEvents.prelive = prelive;
  writeState.battles.dismissedEvents.nav = nav;
};

const defaultQueueId = QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.normalDraft].key[0];
const defaultMapId = MAP_ID.SUMMONERS_RIFT;
export const getOverlayData = ({
  queueId = defaultQueueId,
  mapId = defaultMapId,
} = {}) => {
  return getLeagueOverlayInfo({ queueId, mapId });
};
