import React from "react";
import { t } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import { writeSettings } from "@/app/actions.mjs";
import {
  SettingsContent,
  SettingsDescription,
  SettingsLabel,
  SettingsListItem,
} from "@/hub-settings/Common.style.jsx";
import { ToggleSwitch } from "@/hub-settings/ToggleSwitch.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function HideEventPromosSetting() {
  const {
    settings: {
      battles: { hideEventPromos },
    },
  } = useSnapshot(readState);
  const { activeSubscriber } = useSnapshot(accountRefs);

  return (
    activeSubscriber && (
      <SettingsListItem key="hideEventPromos">
        <SettingsContent>
          <SettingsLabel className="type-form--button">
            {t(
              "common:settings.battles.hideEventPromos",
              "Hide Battles Event Promos",
            )}
          </SettingsLabel>
          <SettingsDescription className="type-caption">
            {t(
              "common:settings.battles.hideEventPromosDesc",
              "Disable seeing Battles event promotionals in app.",
            )}
          </SettingsDescription>
        </SettingsContent>
        <ToggleSwitch
          className=""
          name="hideEventPromos"
          disabled={false}
          value={hideEventPromos}
          onChange={() =>
            writeSettings(["battles", "hideEventPromos"], !hideEventPromos)
          }
        />
      </SettingsListItem>
    )
  );
}
