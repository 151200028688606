import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import {
  BATTLES_NAME,
  HANDLED_GAMES,
  HUB_SYMBOL_BATTLES,
} from "@/feature-battles/constants.mjs";
import { fetchEventDetails } from "@/feature-battles/fetch-event-details.mjs";
import { fetchBattlesHub } from "@/feature-battles/fetch-event-overview.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

const hubTabs = "discover|your-events";
const eventTabs = "overview|scoring|rewards|faq";

export const battlesRoutes = [
  {
    path: pathRegExp(`/${BATTLES_NAME}/:tab(${hubTabs})`),
    hubSymbol: HUB_SYMBOL_BATTLES,
    component: "feature-battles/Battles.tsx",
    fetchData: fetchBattlesHub,
  },
  {
    path: `/${BATTLES_NAME}`,
    redirect: `/${BATTLES_NAME}/discover`,
  },

  // redirects for old Blitz Arena links
  {
    path: pathRegExp("/arena{/:path}*"),
    redirect({ parameters: [path] }) {
      return path ? `/${BATTLES_NAME}/${path}` : `/${BATTLES_NAME}/discover`;
    },
  },
];

export const battlesGameRoutes = Object.getOwnPropertySymbols(
  HANDLED_GAMES,
).reduce((h, gameSymbol) => {
  h[gameSymbol] = [
    {
      path: `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}`,
      hubSymbol: HUB_SYMBOL_BATTLES,
      redirect: `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/discover`,
    },
    {
      path: pathRegExp(
        `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/:tab(${hubTabs})`,
      ),
      hubSymbol: HUB_SYMBOL_BATTLES,
      component: "feature-battles/Battles.tsx",
      fetchData: fetchBattlesHub,
    },
    ...(HANDLED_GAMES[gameSymbol]
      ? [
          {
            path: pathRegExp(
              `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/:id(\\d+)`,
            ),
            redirect({ parameters: [id] }) {
              return `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/${id}/overview`;
            },
          },
          {
            path: pathRegExp(
              `/${BATTLES_NAME}/${GAME_SHORT_NAMES[gameSymbol]}/:id/:tab(${eventTabs})`,
            ),
            component: "feature-battles/BattlesEvent.jsx",
            fetchData: fetchEventDetails,
          },
        ]
      : []),
  ];
  return h;
}, {});
