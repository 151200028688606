import React from "react";
import { styled } from "goober";

export const UserContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-0_5);
`;

export const EventBanner = styled("div", React.forwardRef)`
  grid-column: 1 / -1;
  border-radius: var(--br);
  position: relative;

  height: 120px;

  & > .event-banner {
    border-radius: var(--br);

    width: 100%;
    height: 100%;

    object-fit: none;
    object-position: center;

    background-color: hsl(220deg 61% 7%);
  }

  & > .timer-card {
    position: absolute;
    margin: auto;
    right: var(--sp-11);
    top: 0;
    bottom: 0;
  }
`;
