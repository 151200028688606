import type EventEmitter from "event-lite";

// TODO: emitter should prolly be more generic
type Listener = (...args) => void;
export default function subscribeEvents(
  emitter: EventEmitter,
  eventPairs: [string, Listener, "once" | "all" | undefined][],
) {
  return {
    start() {
      for (const [event, listener, amt] of eventPairs) {
        if (amt === "once") emitter.once(event, listener);
        else emitter.on(event, listener);
      }
    },
    end() {
      for (const [event, listener] of eventPairs) {
        emitter.off(event, listener);
      }
    },
  };
}
