import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import { SidebarScoringInfo } from "@/feature-battles/BattlesEventScoring.jsx";
import * as Styled from "@/feature-battles/BattlesPostMatch.style.jsx";
import {
  BATTLES_NAME,
  CLICK_ACTIONS,
  IMPRESSION_TARGETS,
  LEADERBOARD_PAGE_SIZE,
} from "@/feature-battles/constants.mjs";
import { CountdownTimerCard } from "@/feature-battles/CountdownTimerCard.jsx";
import { useParticipantInfo } from "@/feature-battles/hooks.mjs";
import {
  Leaderboard,
  SubsectionLeaderboard,
} from "@/feature-battles/Leaderboard.jsx";
import {
  Card,
  HelpBox,
  InfoSection,
  UserGameTracker,
} from "@/feature-battles/Shared.jsx";
import { TwoColumnLayout } from "@/feature-battles/Shared.style.jsx";
import { useBattlesAnalytics } from "@/feature-battles/use-battles-analytics.mjs";
import { getMatchInfo } from "@/feature-battles/util-game.mjs";
import {
  findEventForGame,
  getCurrentEvents,
  getEventDetailsRoute,
  sortByFeatured,
} from "@/feature-battles/utils.mjs";
import GemIcon from "@/inline-assets/event-gem.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { useMatchContext } from "@/shared/use-match-context.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import { formatNumberWithSign } from "@/util/i18n-helper.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function BattlesPostMatch({ children, ...layoutProps }) {
  const { t } = useTranslation();
  const {
    battles: { eventMap },
  } = useSnapshot(readState);
  const { currentPath } = useTransientRoute();
  const { tab, tabId, isOwnProfile, match } = useMatchContext();
  const baseRoute = currentPath.replace(`/${tab}/${tabId}`, "");

  const [gameId, tabs] = useMemo(() => {
    const performanceTab = {
      text: t("common:postmatch", "Performance"),
      url: baseRoute,
    };
    if (!match || match instanceof Error) return [null, []];

    const gameSymbol = findGameSymbol();
    const eventList = getCurrentEvents(eventMap, gameSymbol);
    const matchInfo = getMatchInfo({
      game: gameSymbol,
      match,
    });
    if (!matchInfo) return [null, []];

    const { mapName, queueName, startDate, id: gameId } = matchInfo;
    const gameFilter = findEventForGame(queueName, mapName);
    const eventTabs = eventList
      .filter(
        ({ startAt, endAt, game, missions }) =>
          game === gameSymbol &&
          startAt < startDate &&
          startDate < endAt &&
          gameFilter({ missions }),
      )
      .sort(sortByFeatured)
      .map(({ id, displayName }) => ({
        text: displayName,
        url: `${baseRoute}/${BATTLES_NAME}/${id}`,
      }));

    return [gameId, eventTabs.length ? [performanceTab, ...eventTabs] : []];
  }, [baseRoute, eventMap, match, t]);

  const displayTabs =
    tabId && !eventMap[tabId]
      ? [
          ...tabs,
          {
            text: t("common:postmatch.unknown", "Unknown"),
            url: currentPath,
          },
        ]
      : tabs;

  return (
    <SharedMatchLayout {...layoutProps} links={displayTabs}>
      {tab === BATTLES_NAME ? (
        <PostMatchEvent
          eventId={tabId}
          gameId={gameId}
          isOwnProfile={isOwnProfile}
        />
      ) : (
        children
      )}
    </SharedMatchLayout>
  );
}

function PostMatchEvent({ eventId, gameId, isOwnProfile }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    battles: { eventMap, userOptIns, gameResults, eventParticipantInfo },
  } = useSnapshot(readState);

  const { userRanking, userGameCount } = useParticipantInfo(
    eventId,
    eventParticipantInfo,
    userOptIns,
  );
  const event = eventMap[eventId];
  const userOptIn = userOptIns[eventId];
  const gameRecord = gameResults[gameId];

  if (!event) return <LoadingSpinner />;

  const eventHasEnded = event.endAt < new Date();
  const eventHasStarted = event.startAt < new Date();
  const eventDetailsLink = getEventDetailsRoute({
    id: eventId,
    game: event.game,
  });
  return (
    <TwoColumnLayout>
      <h2 className="type-h6 span-all">{event.displayName}</h2>
      <EventBanner
        eventHasStarted={eventHasStarted}
        eventHasEnded={eventHasEnded}
        eventDetailsLink={eventDetailsLink}
        bannerUrl={`${appURLs.BATTLES_CDN}/${event.postMatchBannerImg}`}
        startTime={event.startAt}
        endTime={event.endAt}
        eventId={eventId}
      />
      <TwoColumnLayout.Content>
        {!eventHasEnded && userOptIn?.optedIn && isOwnProfile && (
          <>
            {gameRecord ? (
              Boolean(gameRecord?.blitzPoints) && (
                <HelpBox>
                  {gameRecord.blitzPoints === 1
                    ? t(
                        "battles:postMatch.hint_single",
                        "You earned {{points, number}} point this match!",
                        { points: gameRecord.blitzPoints },
                      )
                    : t(
                        "battles:postMatch.hint_plural",
                        "You earned {{points, number}} points this match!",
                        { points: gameRecord.blitzPoints },
                      )}
                  <div className="flex align-center">
                    {formatNumberWithSign(language, gameRecord.blitzPoints)}
                    <GemIcon />
                  </div>
                </HelpBox>
              )
            ) : (
              <HelpBox>
                {t(
                  "battles:postMatch.hintWaitForPoints",
                  "Thanks for playing! We are counting the points check back in a bit.",
                )}
              </HelpBox>
            )}
            {userRanking && (
              <SubsectionLeaderboard
                missions={event.missions}
                items={[userRanking]}
              />
            )}
          </>
        )}
        <Leaderboard
          eventId={eventId}
          highlightProfileId={eventHasEnded ? userOptIn?.gameAccountId : -1}
        />
        {userRanking &&
          eventHasEnded &&
          userRanking.rank > LEADERBOARD_PAGE_SIZE && (
            <SubsectionLeaderboard
              missions={event.missions}
              items={[userRanking]}
              highlightProfileId={userOptIn.gameAccountId}
            />
          )}
      </TwoColumnLayout.Content>
      <InfoSidebar
        eventHasEnded={eventHasEnded}
        event={event}
        gameRecord={gameRecord}
        userOptIn={userOptIn}
        userRanking={userRanking}
        userGameCount={userGameCount}
        isOwnProfile={isOwnProfile}
      />
    </TwoColumnLayout>
  );
}

function EventBanner({
  bannerUrl,
  startTime,
  endTime,
  eventDetailsLink,
  eventId,
}) {
  const { t } = useTranslation();

  const { impressionRef, handleClickEvent } = useBattlesAnalytics(
    eventId,
    IMPRESSION_TARGETS.bannerPostMatch,
  );

  return (
    <Styled.EventBanner ref={impressionRef}>
      <img
        className="event-banner"
        src={bannerUrl}
        alt={t("battles:postMatch.banner", "Event banner")}
      />
      <CountdownTimerCard
        className="timer-card"
        eventDetailsLink={eventDetailsLink}
        startTime={startTime}
        endTime={endTime}
        onViewDetails={handleClickEvent(CLICK_ACTIONS.details)}
      />
    </Styled.EventBanner>
  );
}

function InfoSidebar({
  eventHasEnded,
  event: { gameLimit, missions },
  gameRecord,
  userOptIn,
  userRanking,
  userGameCount,
  isOwnProfile,
}) {
  if (userOptIn && userRanking && isOwnProfile) {
    return eventHasEnded ? (
      <PostMatchUserInfo userLeaderboardItem={userRanking} />
    ) : (
      <MatchUserInfo
        gameLimit={gameLimit}
        gameRecord={gameRecord}
        missions={missions}
        userRanking={userRanking}
        userGameCount={userGameCount}
      />
    );
  }

  return <NoOptInInfo missions={missions} />;
}

function NoOptInInfo({ missions }) {
  return (
    <TwoColumnLayout.Sidebar>
      <SidebarScoringInfo event={{ missions }} />
    </TwoColumnLayout.Sidebar>
  );
}

function MatchUserInfo({
  gameLimit,
  missions,
  gameRecord,
  userRanking,
  userGameCount,
}) {
  const { t } = useTranslation();

  return (
    <TwoColumnLayout.Sidebar>
      <Card>
        <UserGameTracker maxGames={gameLimit} playedGames={userGameCount} />
      </Card>
      <InfoSection
        title={t("battles:postMatch.info.matchPoints", "Event Points Earned")}
      >
        <Card>
          {t("battles:postMatch.info.currentRank.title", "Current Event Rank")}
          <span>
            {t("battles:postMatch.info.currentRank.info", "#{{rank, number}}", {
              rank: userRanking.rank,
            })}
          </span>
        </Card>
        <Card>
          {t("battles:postMatch.info.totalPoints.title", "Total Points Earned")}
          <div className="flex align-center">
            {t(
              "battles:postMatch.info.totalPoints.info",
              "{{totalScore, number}}",
              { totalScore: userRanking.score },
            )}
            <GemIcon />
          </div>
        </Card>
        {Boolean(gameRecord?.blitzPoints) && (
          <Card>
            {t("battles:postMatch.info.matchPoints.title", "Points This Match")}
            <div className="flex align-center">
              {t(
                "battles:postMatch.info.matchPoints.info",
                "{{totalScore, number}}",
                { totalScore: gameRecord.blitzPoints },
              )}
              <GemIcon />
            </div>
          </Card>
        )}
      </InfoSection>
      <SidebarScoringInfo event={{ missions }} />
    </TwoColumnLayout.Sidebar>
  );
}

function PostMatchUserInfo({ userLeaderboardItem }) {
  const { t } = useTranslation();

  return (
    <TwoColumnLayout.Sidebar>
      <InfoSection
        title={t("battles:postMatch.info.results", "Results Summary")}
      >
        <>
          {t("battles:postMatch.info.finalRank.title", "Event Rank")}
          <span>
            {t("battles:postMatch.info.finalRank.info", "#{{rank, number}}", {
              rank: userLeaderboardItem.rank,
            })}
          </span>
        </>
        <>
          {t("battles:postMatch.info.totalMissions.title", "Total Objectives")}
          <span>
            {t(
              "battles:postMatch.info.totalMissions.info",
              "{{missionCompletions, number}}",
              {
                missionCompletions: userLeaderboardItem.missionCompletions ?? 0,
              }, // TODO get this info for placements
            )}
          </span>
        </>
        <>
          {t("battles:postMatch.info.totalPoints.title", "Total Points Earned")}
          <div className="flex align-center">
            {t(
              "battles:postMatch.info.matchPoints.info",
              "{{totalScore, number}}",
              { totalScore: userLeaderboardItem.score },
            )}
            <GemIcon />
          </div>
        </>
      </InfoSection>
    </TwoColumnLayout.Sidebar>
  );
}
