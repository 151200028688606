import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { getEvents } from "@/feature-battles/api.mjs";
import { EVENT_STORAGE_TIME } from "@/feature-battles/constants.mjs";
import {
  fetchUserOptIns,
  getCurrentFilters,
} from "@/feature-battles/fetch-event-overview.mjs";
import type { BattlesEvent } from "@/feature-battles/model-events.mjs";
import EventsModel from "@/feature-battles/model-events.mjs";
import type { EventMap, EventQueue } from "@/feature-battles/util-game.mjs";
import {
  findEventForGame,
  // mergeEvents,
  sortByFeatured,
} from "@/feature-battles/utils.mjs";
import { appLog, devDebug, devError } from "@/util/dev.mjs";

export interface OverlayCriteria {
  displayName: string;
  event: string;
  amount: number;
  /// Total amount of points that fulfilling this criteria will give
  points: number;
}

export interface OverlayEventInfo {
  title: string;
  remainingGames: number;
  /// Blob of logo
  logoUrl: string;
  /// Blob of icon
  iconUrl: string;
  missions: {
    id: string;
    displayName: string;
    points: number;
    /// Fulfilling any one criteria completes the mission.
    criteriaEvents: OverlayCriteria[];
  }[];
}

export async function getHashedSecurityHeader({
  userId,
  gameAccountId,
}): Promise<string> {
  if (IS_APP) {
    return (await blitzMessage(
      EVENTS.HASH_MESSAGE,
      `${gameAccountId}${userId}`,
    )) as string;
  }

  devError("You can only send game records when running in app");
}

export async function getOverlayEvent({
  gameSymbol,
  queueName,
  mapName,
}: {
  gameSymbol: GameSymbol;
  queueName: EventQueue;
  mapName: EventMap;
}): Promise<BattlesEvent> {
  const filters = await getCurrentFilters();
  await Promise.all([
    getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
      networkBackOffTime: EVENT_STORAGE_TIME,
      skipSafetyCheck: true,
      // mergeFn: mergeEvents,
    }),
    fetchUserOptIns(filters, { skipSafetyCheck: true }),
  ]);

  const now = new Date();
  const { eventMap, userOptIns } = readState.battles;
  return Object.values(eventMap)
    .filter((event) => {
      const { id, startAt, endAt, gameLimit, game } = event;
      if (game !== gameSymbol) return false;

      const optIn = userOptIns[id];
      return (
        optIn?.optedIn &&
        startAt < now &&
        now < endAt &&
        optIn.gameCount < gameLimit
      );
    })
    .sort(sortByFeatured)
    .find(findEventForGame(queueName, mapName));
}

export function setOverlayEvent(event: OverlayEventInfo | null): Promise<void> {
  if (!event) appLog(`[BATTLES]: no battles event found`);

  // Debug in testing.
  if (!IS_APP) devDebug("[BATTLES]: overlay info", event ?? "null");

  // TODO: rename event to SET_BATTLES_EVENT in core
  return blitzMessage?.(EVENTS.SET_ARENA_EVENT, event) as Promise<void>;
}
