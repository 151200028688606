import { readState } from "@/__main__/app-state.mjs";
import { IS_NODE } from "@/__main__/constants.mjs";
import getData, { readData } from "@/__main__/get-data.mjs";
import { initAuth } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import {
  addDismissedNavEvent,
  cleanOldEvents,
  handlePastEventSort,
} from "@/feature-battles/actions.mjs";
import type { EventsQueryParams } from "@/feature-battles/api.mjs";
import {
  getEvents,
  getParticipantInfo,
  getPastEvents,
  getUserOptIns,
} from "@/feature-battles/api.mjs";
import {
  EVENT_STORAGE_TIME,
  PAST_EVENT_PAGE_SIZE,
} from "@/feature-battles/constants.mjs";
import EventsModel from "@/feature-battles/model-events.mjs";
import ParticipantInfoModel from "@/feature-battles/model-participants-info.mjs";
import UserOptInsModel from "@/feature-battles/model-user-opt-in.mjs";
import PastEventModel from "@/feature-battles/model-user-past-event.mjs";
import {
  getCurrentEvents,
  // mergeEvents,
  // mergePastEvents,
} from "@/feature-battles/utils.mjs";
import { devError } from "@/util/dev.mjs";
import { fetchCountryCode } from "@/util/use-country-code.mjs";

export async function fetchBattlesHub(_params, _searchParam, state) {
  const requiredPromises = [];
  const sideLoadPromises = [];
  const isUpdate = Boolean(state?.isUpdate);

  const filters = await getCurrentFilters();
  requiredPromises.push(
    readData(["battles", "defaultAccount"], { initializeOnce: true }),
    getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
      networkBackOffTime: EVENT_STORAGE_TIME,
      // mergeFn: mergeEvents,
      shouldFetchIfPathExists: !isUpdate,
    }),
  );

  sideLoadPromises.push(
    fetchUserOptIns(filters, {
      skipSafetyCheck: true,
      shouldFetchIfPathExists: !isUpdate,
    }),
    fetchUserPastEvents(state),
    getData(
      getParticipantInfo(filters),
      ParticipantInfoModel,
      ["battles", "eventParticipantInfo"],
      {
        // mergeFn: mergeEvents,
        shouldFetchIfPathExists: !isUpdate,
      },
    ),
  );

  Promise.all(sideLoadPromises).catch((error) => {
    devError("FETCH EVENTS SIDELOAD", error);
  });

  await Promise.all(requiredPromises);
  const eventIds = getCurrentEvents(readState.battles.eventMap).map(
    ({ id }) => id,
  );
  addDismissedNavEvent(eventIds);
}

export function fetchBattlesAdEvents() {
  return Promise.all([fetchCurrentEvents()]);
}

const pastEventBackOffTime = 5 * 60 * 1000;
async function fetchUserPastEvents(state) {
  if (IS_NODE) return;
  await initAuth();
  const bearerToken = await getBearerToken();

  if (bearerToken) {
    const startRow = state?.transient?.startRow ?? 0;
    const order = state?.transient?.sortDir ?? "DESC";
    handlePastEventSort(order);

    return getData(
      getPastEvents({
        displayEndAt: { lte: getNowUTC() },
        offset: startRow,
        first: PAST_EVENT_PAGE_SIZE,
        order,
      }),
      PastEventModel,
      ["battles", "pastEvents"],
      {
        headers: { Authorization: bearerToken },
        skipSafetyCheck: true,
        shouldSkipDBRead: true,
        // mergeFn: mergePastEvents,
        networkBackOffTime: pastEventBackOffTime,
      },
    );
  }
}

type FetchInfo = {
  skipSafetyCheck?: boolean;
  shouldFetchIfPathExists?: boolean;
  mergeFn?: <T>(a: T, b: T) => T;
};
async function fetchCurrentEvents() {
  const filters = await getCurrentFilters();
  await getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
    networkBackOffTime: EVENT_STORAGE_TIME,
    // mergeFn: mergeEvents,
  });
  cleanOldEvents();
}

/// IMPORTANT: this calls initAuth and so should always be a side effect or it will cause sadness
export async function fetchUserOptIns(
  filters: EventsQueryParams,
  options: FetchInfo = {},
) {
  if (IS_NODE) return;
  await initAuth();
  const bearerToken = await getBearerToken();
  if (!bearerToken) return;

  return getData(
    getUserOptIns(filters),
    UserOptInsModel,
    ["battles", "userOptIns"],
    {
      headers: { Authorization: bearerToken },
      // mergeFn: mergeEvents,
      ...options,
    },
  );
}

const getNowUTC = () =>
  new Date(
    ~~(Date.now() / EVENT_STORAGE_TIME) * EVENT_STORAGE_TIME,
  ).toISOString();
export async function getCurrentFilters() {
  // quantize by hour intervals so we don't spam slightly different requests.
  const nowUTC = getNowUTC();
  const regions = (await getRegions()).map((region) => region.toUpperCase());
  return {
    regions,
    displayStartAt: { lte: nowUTC },
    displayEndAt: { gte: nowUTC },
  };
}

// TODO: When we support multiple games CMS will need to decide on a standard regions set for all games
// or handle on a per-country basis instead of per region.
// Currently this just uses league regions for everything.
// HACK: TODO: get a better country to server region map
const na1Countries = ["US", "CA"];
async function getRegions() {
  const countryCode = await fetchCountryCode({
    // FIXME: bugged safety check.
    skipSafetyCheck: true,
  });
  return countryCode && !na1Countries.includes(countryCode)
    ? ["euw1", "all"]
    : ["na1", "all"];
}
